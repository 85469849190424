.tbpBackground {
  /* background-image: url('../../public/temp_background.png'); */
  background-image: url('../../public/home/background.gif');
  position: fixed;
  z-index: -1;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 5em 10em 0.25em #000;
  color: white;
}
