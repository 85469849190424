html {
  -webkit-scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

body {
  background-color: #222;
}

.MuiBackdrop-root {
  pointer-events: none !important;
}

a {
  color: gold !important;
}
