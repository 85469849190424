.background {
  background-image: url('../../public/home/background.gif');
  /* background-image: url('../../public/temp_background.png'); */
  position: sticky;
  z-index: -1;
  top: 0;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 5em 10em 0.25em #000;
  color: white;
}

#who-we-are-text {
  font-size: 1.5rem;
}

@media only screen and (max-width: 640px) {
  .background {
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://tbp.seas.ucla.edu/static/img/background.gif); */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../public/temp_background.png');
    position: sticky;
    box-shadow: inset 0 5em 10em 0.25em #000;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 25%;
  }
  #who-we-are-text {
    font-size: 1.2rem;
  }
}

.section-secondary {
  background-color: rgba(0, 0, 0, 0);
  padding-bottom: 25vh;
}

.landing-text {
  position: absolute;
  width: 80%;
  color: white;
}

.landing-middle {
  top: 40%;
}

.scroll-down a {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  /* display: inline-block; */
  /* -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%); */
  color: #fff;
  transform: scale(2);
  /* font: normal 400 20px/1 'Josefin Sans', sans-serif; */
  /* letter-spacing: 0.1em; */
  /* text-decoration: none; */
  /* transition: opacity 0.3s; */
}
.arrow {
  opacity: 0;
  position: absolute;
  /* left: -webkit-calc(50% - 15px);
  left: -moz-calc(50% - 15px); */
  left: calc(50% - 25px);
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate3d(-50%, -50%, 0);
}
.arrow-first {
  animation: arrow-movement 3s ease-in-out infinite;
}
.arrow-second {
  animation: arrow-movement 3s 1.5s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
  background: #ccc;
  content: '';
  display: block;
  height: 3.5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
}

.arrow:before {
  transform: rotate(30deg) translateX(-39%);
  transform-origin: top left;
}

.arrow:after {
  transform: rotate(-30deg) translateX(39%);
  transform-origin: top right;
}

@keyframes arrow-movement {
  0% {
    opacity: 0;
    top: -50px;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.header {
  margin: 0 0 20px 0;
  font-size: 2.7rem;
  line-height: 1.2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 400;
  color: #fff;
  text-transform: none;
}

.section-container {
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px;
  padding-left: 15px;
  padding-right: 15px;
}

iframe {
  overflow-clip-margin: content-box !important;
  overflow: clip !important;
  padding-bottom: 50px;
}

#email-link {
  color: white;
}

.last-section {
  padding-bottom: 100px;
}

.card-background {
  background: #2d2d2d;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.4;
  color: white;
}

.card-text {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  font-size: 0.9rem;
  line-height: 1.2;
  color: #bdbdbd;
}
